.mbsc-datepicker {
  @apply !bg-tertiary-4 max-w-screen-xs border border-tertiary-2 rounded-sm;

  .mbsc-calendar-slide {
    @apply !bg-tertiary-4;
  }

  .mbsc-calendar-button-prev, .mbsc-calendar-button-next {
    @apply !text-primary-3;
  }

  .mbsc-calendar-title-wrapper button {
    @apply !font-tertiary !text-ms0;
  }
}