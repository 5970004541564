@keyframes slidein-left {
  from {
    transform: translate(-250px,50px);
    opacity: 1;
  }

  to {
    transform: translateX(0px,0px);
    opacity: 1;
  }
}

.smoke-animation {
  animation-duration: 5s;
  animation-name: slidein-left;
  animation-timing-function: ease-in-out;
}

.page-header-anchored-cta {
  .link-cta {
    @apply text-white text-ms2 font-normal;
  }

  @screen md {
    .animated-cta {
      animation-name: slidein-left;
      animation-duration: 1s;
      animation-timing-function: ease-in-out;
      
      @media (prefers-reduced-motion) {
        animation-duration: 0s;
      }
    }
  }
}

.page-header-overlay {
  &:after {
    content: "";
    background-image: linear-gradient(0deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.75) 94%);
    @apply absolute inset-0 h-[250px];
  }
}

@keyframes slidein-left {
  from {
    transform: translateX(100%);
    opacity: 1;
  }

  to {
    transform: translateX(0%);
    opacity: 1;
  }
}