.veteran-bio .accordion {
  & > div:last-child {
    @apply hidden;

    &.open {
      @apply block;
    }
  }
}

#veteranTable_info {
  @apply after:content-["*"];
}

#veteranTable_filter {
  @apply hidden;
}

.dataTables_wrapper {
  @apply flex flex-wrap w-full;

  .dataTables_paginate {
    @apply ml-auto flex;

    & > span {
      @apply hidden;
    }

    .previous {
      @apply mr-ms2;
    }

    .previous, .next {
      @apply text-primary-3 font-medium flex items-center hover:cursor-pointer hover:before:translate-x-[-10px] hover:after:translate-x-[10px];
    }

    .previous:before {
      @apply icon icon-keyboard_arrow_right text-ms2 rotate-180 transition duration-200 ease-in-out;
    }

    .next:after {
      @apply icon icon-keyboard_arrow_right text-ms2 transition duration-200 ease-in-out;
    }
  }

  .dataTables_info, .dataTables_paginate {
    @apply text-ms0;
  }
}

.dataTable {
  @apply relative w-full mb-ms6;

  &:after {
    content: "";
    @apply absolute top-full h-[1px] w-full bg-primary-4 mt-ms4;
  }

  thead {
    @apply text-left text-[20px];

    th {
      @apply font-normal pb-ms-1;

      &:after {
        @apply icon align-middle text-primary-3 text-ms2;
      }

      &.sorting_asc:after {
        @apply icon-arrow_ascending;
      }

      &.sorting_desc:after {
        @apply icon-arrow_descending;
      }
    }
  }

  tbody {
    @apply text-ms0;

    tr {
      @apply relative hover:!bg-tertiary-2 hover:text-tertiary-3 transition duration-200 ease-in-out cursor-pointer;

      a {
        /* &:after {
          content: "";
          @apply absolute inset-0;
        } */
      }

      &.odd {
        @apply bg-tertiary-3;
      }

      td {
        @apply py-ms-2;
      }
    }
  }

  th, td {
    @apply px-ms-2;
  }
}

.veteran-filters {
  @apply lg:flex items-stretch mb-ms6;
}

.filter-wrap {
  @apply md:flex items-center [&>*]:mr-ms3 first:[&>*]:mr-ms0 last:mr-0;

  & > span {
    @apply block mb-ms0 md:mb-0;
  }
}

/* .veteran-search {
  @apply border border-primary-3 rounded-full inline-block lg:flex items-center lg:mr-ms5 py-ms-3 mb-ms2 lg:mb-0;

  label {
    @apply relative pl-ms1 pr-ms3 after:icon after:icon-search after:absolute after:right-ms-1 after:top-[50%] after:translate-y-[-50%];
  }

  input {
    @apply bg-transparent placeholder-tertiary-1;
  }
}

.veteran-filter-control {
  @apply relative inline-block mb-ms0 md:mb-0;

  button {
    @apply flex justify-between items-center font-medium bg-primary-3 text-tertiary-4 rounded-full px-ms-2 py-ms-4 text-left after:icon after:icon-keyboard_arrow_down after:text-ms2;

    span {
      @apply w-[180px] overflow-hidden whitespace-nowrap;
    }
  }

  fieldset {
    @apply hidden absolute left-0 top-full mt-ms-4 w-full bg-white shadow;
    @apply [&_span]:block [&_span]:px-ms-2 [&_span]:py-ms-4 [&_input:checked+span]:bg-primary-3 [&_input:checked+span]:text-tertiary-4 z-20;

    &.open {
      @apply block;
    }

    input {
      @apply sr-only;
    }
  }
} */