.carousel-event-list {

  .tns-nav {
    @apply hidden;
  }

  .tns-item:not(.tns-slide-active) {
    mask-image: linear-gradient(to left, transparent 25%, transparent 75%);

    a {
      @apply invisible;
    }
  }

  .tns-item.tns-slide-active + .tns-item:not(.tns-slide-active) {
    mask-image: linear-gradient(to left, transparent 25%, black 75%);
  }

  /* .tns-item:not(.tns-slide-active):has(+ .tns-item.tns-slide-active) {
    mask-image: linear-gradient(to right, transparent 25%, black 75%);
  } */

  .link-cta {
    &:before {
      content:"";
      @apply absolute top-0 bottom-0 left-0 right-0;
    }
  }

  .card {
    @apply transition duration-200 ease-in-out;

    &:hover {
      @apply translate-y-[-10px];
    }
  }

  .tns-outer {
    @apply relative;
  }

  .tns-ovh {
    @screen md {
      @apply overflow-visible;
    }
  }

  .tns-controls {
    @apply absolute left-0 top-2/4 translate-y-[-50%] w-full flex justify-between z-10;

    &:focus {
      button {
        @apply outline outline-1 outline-primary-3 outline-offset-4;
      }
    }

    button {
      @apply rounded-full bg-primary-3 text-tertiary-3 w-[50px] h-[48px] text-ms2 absolute left-0 z-10;

      &:hover, &:focus {
        @apply outline outline-primary-3 outline-1 outline-offset-4;
      }

      &:active {
        @apply bg-[#780000] outline-0;
      }

      span {
        @apply inline-block;
      }

      &:first-child {
        @apply translate-x-[-24px] translate-y-[-50%];
      }

      &:last-child {
        @apply translate-x-[24px] translate-y-[-50%] right-0 left-auto;
      }

      &:disabled {
        @apply invisible;
      }
    }
  }
}