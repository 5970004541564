.weapons-grid {
  @apply grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-ms3;

  .artDesc {
    @apply text-center mt-ms0;
  }

  div.smugmug-image-container:nth-child(n+9):not(.revealed) {
    @apply hidden;
  }

  div.smugmug-image-container.filtered {
    @apply hidden;
  }
}