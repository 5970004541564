.page-header-image {
  .header-overlay {
    &:before {
      content: "";
      background-image: linear-gradient(-90deg, rgba(0,0,0,0.00) 1%, rgba(0,0,0,0.25) 64%, rgba(0,0,0,0.40) 99%);
      @apply absolute inset-0;
    }

    &:after {
      content: "";
      background-image: linear-gradient(0deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.75) 94%);
      @apply absolute inset-0 h-[250px];
    }
  }

  @apply after:bg-[url('/images/san_jacinto_2022/tan-smoke-textured.png')];
  @apply after:w-full after:h-[55px];
  @apply after:absolute after:left-1/2 after:-translate-x-1/2 after:bottom-0;
  @apply after:bg-[length:2560px] after:bg-center;
}