.social-link {
  @apply text-ms1 text-primary-4 transition duration-200 ease-in-out;
  @apply w-[40px] h-[40px] flex items-center justify-center rounded-full border border-primary-4;

  &:hover, &:focus {
    @apply text-primary-2 bg-primary-4;
  }
}

.site-footer {
  a:not(.social-link,.link-cta) {
    @apply underline transition-all duration-200 ease-in-out;
    text-underline-offset: 5px;
    text-decoration-color: rgba(255, 255, 255, 0);

    &:hover, &:focus, a.active {
      text-decoration-color: rgba(255, 255, 255, 1);
    }
  }

  details {
    summary {
      .icon-expand-more {
        @apply absolute top-2/4 right-0  text-ms-2;
        transform: translateY(-50%);

        &:before {
          width: auto;
        }
      }
    }

    &[open] {
      summary {
        .icon-expand-more {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }
  }
}