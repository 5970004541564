.smugmug-slider-two-column {
  &.inverse-order {
    .grid {
      .content {
        @apply order-2 md:col-start-7;
      }

      .slider-container {
        @apply order-1 md:col-start-1;
      }
    }
  }
}