@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');
@import url('https://use.typekit.net/sjr0qpy.css');

@layer base {
  @font-face {
    font-family: 'Ogg';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("/fonts/san_jacinto_2022/Ogg/Ogg-Medium.woff2") format('woff2'),
         url("/fonts/san_jacinto_2022/Ogg/Ogg-Medium.woff") format('woff'),
         url("/fonts/san_jacinto_2022/Ogg/Ogg-Medium.otf") format("opentype"),
         url("/fonts/san_jacinto_2022/Ogg/Ogg-Medium.ttf") format('truetype');
  }

  @font-face {
    font-family: 'Ogg Text';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("/fonts/san_jacinto_2022/OggText/OggText-Medium.woff2") format('woff2'),
         url("/fonts/san_jacinto_2022/OggText/OggText-Medium.woff") format('woff'),
         url("/fonts/san_jacinto_2022/OggText/OggText-Medium.otf") format("opentype"),
         url("/fonts/san_jacinto_2022/OggText/OggText-Medium.ttf") format('truetype');
  }

  @font-face {
    font-family: 'Ogg Text';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("/fonts/san_jacinto_2022/OggText/OggText-Book.woff2") format('woff2'),
         url("/fonts/san_jacinto_2022/OggText/OggText-Book.woff") format('woff'),
         url("/fonts/san_jacinto_2022/OggText/OggText-Book.otf") format("opentype"),
         url("/fonts/san_jacinto_2022/OggText/OggText-Book.ttf") format('truetype');
  }

  @font-face {
    font-family: 'Ogg Text';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url("/fonts/san_jacinto_2022/OggText/OggText-lightItalic.woff2") format('woff2'),
         url("/fonts/san_jacinto_2022/OggText/OggText-lightItalic.woff") format('woff'),
         url("/fonts/san_jacinto_2022/OggText/OggText-lightItalic.otf") format("opentype"),
         url("/fonts/san_jacinto_2022/OggText/OggText-lightItalic.ttf") format('truetype');
  }
}
