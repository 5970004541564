.two-column-cover {
  div[itemprop="itemListElement"] {
    --data-position: 1;
    order: var(--data-position);

    @screen md {
      order: unset;
    }

    &:nth-child(odd):has(+ div p:only-child > img:only-child) {
      order: calc(var(--data-position) + 1);

      @screen md {
        order: unset;
      }
    }

    &:nth-child(even):has(p:only-child > img:only-child) {
      order: calc(var(--data-position) - 1);

      @screen md {
        order: unset;
      }
    }
  }
}