.smugSlider {
  @apply relative;

  img {
    @apply aspect-square object-contain;
  }

  &[data-slides="1"] {
    img {
      @apply aspect-[1.481] w-full;
    }
  }

  .carousel__counter {
    @apply font-tertiary;
  }

  .tns-outer {
    @apply relative !mx-ms1 sm:!mx-0;
  }

  .tns-nav {
    @apply hidden;
  }

  /* .slider > div {
    @apply px-ms0;
  } */

  .slider.empty + .carousel__counter {
    @apply hidden;
  }

  .tns-controls {
    @apply absolute left-0 top-2/4 translate-y-[-50%] w-full flex justify-between z-10;

    &:focus {
      button {
        @apply outline outline-1 outline-primary-3 outline-offset-4;
      }
    }

    button {
      @apply rounded-full bg-tertiary-4 text-primary-3 border border-primary-3 w-[32px] h-[30px] xl:w-[50px] xl:h-[48px] text-ms2 absolute left-0 xl:-left-ms0 z-10;
      @apply transition duration-200 ease-in-out;
      @apply flex items-center justify-center;

      &:hover, &:focus {
        @apply bg-primary-3 text-tertiary-4;
      }

      &:active {
        @apply bg-[#780000] outline-0;
      }

      span {
        @apply inline-block;
      }

      &:first-child {
        @apply -translate-x-full translate-y-[-50%];
      }

      &:last-child {
        @apply translate-x-full translate-y-[-50%] right-0 xl:-right-ms0 left-auto;
      }

      &:disabled {
        @apply invisible;
      }
    }
  }
}