.carousel-default {
  .tns-controls {
    @apply hidden bg-secondary-2 text-secondary-1 font-primary text-center py-ms-1;

    button {
      @apply px-ms0 capitalize;
    }
  }

  .tns-nav {
    @apply hidden;
  }
}