@layer components {
  .two-column-cover-image {
    div[itemprop="itemListElement"] {
      --data-position: 1;
      order: var(--data-position);

      @screen md {
        order: unset;
      }

      &:nth-child(odd):has(+ div p:only-child > img:only-child), &:nth-child(odd):has(+ div canvas) {
        order: calc(var(--data-position) + 1);

        @screen md {
          order: unset;
        }
      }

      &:nth-child(even):has(p:only-child > img:only-child), &:nth-child(even):has(canvas) {
        order: calc(var(--data-position) - 1);

        @screen md {
          order: unset;
        }
      }
    }
  }
}