#monument {
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);

  &:before {
    content: "";
    @apply h-[1860px] w-auto absolute top-0 left-[50%] translate-y-[-35%] translate-x-[-50%] aspect-[0.68] opacity-50;
    background: -moz-radial-gradient(49.96% 34.44%, rgba(82, 80, 157, 1) 0%, rgba(82, 80, 157, 0) 99.94%);
    background: -webkit-radial-gradient(49.96% 34.44%, rgba(82, 80, 157, 1) 0%, rgba(82, 80, 157, 0) 99.94%);
    background: -webkit-gradient(radial,49.96% 34.44%, 0, 49.96% 34.44%, 641.23 ,color-stop(0,rgba(82, 80, 157, 1) ),color-stop(0.9994,rgba(82, 80, 157, 0) ));
    background: -o-radial-gradient(49.96% 34.44%, rgba(82, 80, 157, 1) 0%, rgba(82, 80, 157, 0) 99.94%);
    background: -ms-radial-gradient(49.96% 34.44%, rgba(82, 80, 157, 1) 0%, rgba(82, 80, 157, 0) 99.94%);
    background: radial-gradient(49.96% 34.44%, rgba(82, 80, 157, 1) 0%, rgba(82, 80, 157, 0) 99.94%);
  }
}

#animation-container {
  /* max-width: 1400px;
  margin: auto; */
}

#sunrays {
  /* background : -moz-radial-gradient(50% 50%,  rgba(255, 208, 203, 1) 35.59%, rgba(231, 194, 199, 0.89) 42.35%, rgba(144, 145, 185, 0.45) 68.15%, rgba(89, 114, 176, 0.15) 86.32%, rgba(68, 102, 173, 0) 95.1%);
  background : -webkit-radial-gradient(50% 50%,  rgba(255, 208, 203, 1) 35.59%, rgba(231, 194, 199, 0.89) 42.35%, rgba(144, 145, 185, 0.45) 68.15%, rgba(89, 114, 176, 0.15) 86.32%, rgba(68, 102, 173, 0) 95.1%);
  background : -webkit-gradient(radial,50% 50% ,135.8 , 50% 50%, 381.55 ,color-stop(0.3559,rgba(255, 208, 203, 1) ),color-stop(0.4235,rgba(231, 194, 199, 0.89) ),color-stop(0.6815,rgba(144, 145, 185, 0.45) ),color-stop(0.8632,rgba(89, 114, 176, 0.15) ),color-stop(0.951,rgba(68, 102, 173, 0) ));
  background : -o-radial-gradient(50% 50%,  rgba(255, 208, 203, 1) 35.59%, rgba(231, 194, 199, 0.89) 42.35%, rgba(144, 145, 185, 0.45) 68.15%, rgba(89, 114, 176, 0.15) 86.32%, rgba(68, 102, 173, 0) 95.1%);
  background : -ms-radial-gradient(50% 50%,  rgba(255, 208, 203, 1) 35.59%, rgba(231, 194, 199, 0.89) 42.35%, rgba(144, 145, 185, 0.45) 68.15%, rgba(89, 114, 176, 0.15) 86.32%, rgba(68, 102, 173, 0) 95.1%);
  background : radial-gradient(50% 50%,  rgba(255, 208, 203, 1) 35.59%, rgba(231, 194, 199, 0.89) 42.35%, rgba(144, 145, 185, 0.45) 68.15%, rgba(89, 114, 176, 0.15) 86.32%, rgba(68, 102, 173, 0) 95.1%); */


  background : -moz-radial-gradient(50% 50%,  rgba(255, 208, 203, 0) 6.98%, rgba(248, 204, 202, 0.94) 42.28%, rgba(127, 135, 182, 0.32) 80.15%, rgba(68, 102, 173, 0) 100%);
  background : -webkit-radial-gradient(50% 50%,  rgba(255, 208, 203, 0) 6.98%, rgba(248, 204, 202, 0.94) 42.28%, rgba(127, 135, 182, 0.32) 80.15%, rgba(68, 102, 173, 0) 100%);
  background : -webkit-gradient(radial,50% 50% ,100.48 , 50% 50%, 1440 ,color-stop(0.0698,rgba(255, 208, 203, 0) ),color-stop(0.4228,rgba(248, 204, 202, 0.94) ),color-stop(0.8015,rgba(127, 135, 182, 0.32) ),color-stop(1,rgba(68, 102, 173, 0) ));
  background : -o-radial-gradient(50% 50%,  rgba(255, 208, 203, 0) 6.98%, rgba(248, 204, 202, 0.94) 42.28%, rgba(127, 135, 182, 0.32) 80.15%, rgba(68, 102, 173, 0) 100%);
  background : -ms-radial-gradient(50% 50%,  rgba(255, 208, 203, 0) 6.98%, rgba(248, 204, 202, 0.94) 42.28%, rgba(127, 135, 182, 0.32) 80.15%, rgba(68, 102, 173, 0) 100%);
  background : radial-gradient(50% 50%,  rgba(255, 208, 203, 0) 6.98%, rgba(248, 204, 202, 0.94) 42.28%, rgba(127, 135, 182, 0.32) 80.15%, rgba(68, 102, 173, 0) 100%);



  mask-image: url(/images/san_jacinto_2022/sun-rays-mask.svg);

  opacity: 0.7;
  transform: translate3d(0, 0, 0);
}

#animation-container #monument {
  width: 100%;
  height: auto;
  transform: scale(1,1);
  transform-origin: top;
}

#animation-container .grid {
  padding-top: 350px;
  padding-bottom: 250px;

  @apply opacity-0 transition-opacity duration-500 ease-in-out;
}

.scroll-started #animation-container .grid {
  @apply opacity-100;
}

@media only screen and (min-width: 768px) {
  /* #animation-container .grid {
    padding-bottom: 500px;
  } */
}

.gs_reveal {
  @apply opacity-0 motion-reduce:opacity-100;
}

.bg-gradient {
  background : linear-gradient(0deg,rgba(167, 143, 212, 1) , rgba(100, 95, 196, 1));

  @screen lg {
    background : -moz-linear-gradient(50% 100% 90deg,rgba(27, 82, 157, 1) 0.99%,rgba(219, 168, 233, 1) 48.23%,rgba(226, 181, 227, 0.86) 53.09%,rgba(244, 215, 210, 0.62) 62.04%,rgba(254, 233, 201, 0.5) 66.17%,rgba(245, 198, 230, 1) 79.7%,rgba(223, 184, 222, 1) 82.75%,rgba(167, 147, 200, 1) 89.1%,rgba(97, 102, 174, 1) 96.21%);
    background : -webkit-linear-gradient(90deg, rgba(27, 82, 157, 1) 0.99%, rgba(219, 168, 233, 1) 48.23%, rgba(226, 181, 227, 0.86) 53.09%, rgba(244, 215, 210, 0.62) 62.04%, rgba(254, 233, 201, 0.5) 66.17%, rgba(245, 198, 230, 1) 79.7%, rgba(223, 184, 222, 1) 82.75%, rgba(167, 147, 200, 1) 89.1%, rgba(97, 102, 174, 1) 96.21%);
    background : -webkit-gradient(linear,50% 100% ,50% 0% ,color-stop(0.0099,rgba(27, 82, 157, 1) ),color-stop(0.4823,rgba(219, 168, 233, 1) ),color-stop(0.5309,rgba(226, 181, 227, 0.86) ),color-stop(0.6204,rgba(244, 215, 210, 0.62) ),color-stop(0.6617,rgba(254, 233, 201, 0.5) ),color-stop(0.797,rgba(245, 198, 230, 1) ),color-stop(0.8275,rgba(223, 184, 222, 1) ),color-stop(0.891,rgba(167, 147, 200, 1) ),color-stop(0.9621,rgba(97, 102, 174, 1) ));
    background : -o-linear-gradient(90deg, rgba(27, 82, 157, 1) 0.99%, rgba(219, 168, 233, 1) 48.23%, rgba(226, 181, 227, 0.86) 53.09%, rgba(244, 215, 210, 0.62) 62.04%, rgba(254, 233, 201, 0.5) 66.17%, rgba(245, 198, 230, 1) 79.7%, rgba(223, 184, 222, 1) 82.75%, rgba(167, 147, 200, 1) 89.1%, rgba(97, 102, 174, 1) 96.21%);
    background : -ms-linear-gradient(90deg, rgba(27, 82, 157, 1) 0.99%, rgba(219, 168, 233, 1) 48.23%, rgba(226, 181, 227, 0.86) 53.09%, rgba(244, 215, 210, 0.62) 62.04%, rgba(254, 233, 201, 0.5) 66.17%, rgba(245, 198, 230, 1) 79.7%, rgba(223, 184, 222, 1) 82.75%, rgba(167, 147, 200, 1) 89.1%, rgba(97, 102, 174, 1) 96.21%);
    background : linear-gradient(0deg, rgba(27, 82, 157, 1) 0.99%, rgba(219, 168, 233, 1) 48.23%, rgba(226, 181, 227, 0.86) 53.09%, rgba(244, 215, 210, 0.62) 62.04%, rgba(254, 233, 201, 0.5) 66.17%, rgba(245, 198, 230, 1) 79.7%, rgba(223, 184, 222, 1) 82.75%, rgba(167, 147, 200, 1) 89.1%, rgba(97, 102, 174, 1) 96.21%);
  }
}