.smugSlider {
  .tns-item:not(.tns-slide-active) {
    @apply invisible;
  }
}

.smugmug-slider-two-column, .smugmug-slider-default {
  &.bg-green {
    @apply bg-secondary-1 text-white;
    @apply my-0 py-ms6;

    .smugmug-image-container > div:first-child {
      @apply bg-[#d7cec3];
    }
  }

  &.bg-blue {
    @apply bg-primary-1 text-white;
    @apply my-0 py-ms6;

    .smugmug-image-container > div:first-child {
      @apply bg-[#d7cec3];
    }
  }

  &.bg-mocha {
    @apply bg-[#8D7156] text-white bg-[url('/images/san_jacinto_2022/concrete-wall.png')];
    @apply my-0 py-ms6;

    .smugmug-image-container > div:first-child {
      @apply bg-[#d7cec3];
    }
  }

  &.bg-limestone {
    @apply last:pb-ms4;
    @apply bg-tertiary-3;
    @apply my-0 py-ms2;
    @apply [&+div]:pt-[55px] [&+div]:mt-0;

    @apply before:bg-[url('/images/san_jacinto_2022/tan-smoke-textured.png')];
    @apply before:w-full before:h-[55px];
    @apply before:absolute before:left-1/2 before:-translate-x-1/2 before:bottom-full before:translate-y-[2px];
    @apply before:z-10;
    @apply before:bg-[length:2560px] before:bg-center;

    @apply after:bg-[url('/images/san_jacinto_2022/tan-smoke-textured.png')] after:rotate-[180deg];
    @apply after:w-full after:h-[55px];
    @apply after:absolute after:left-1/2 after:-translate-x-1/2 after:top-full after:-translate-y-[2px];
    @apply after:z-10;
    @apply after:bg-[length:2560px] after:bg-center;
  }
}

main > div:has(+ div[class*="smugmug-slider"].bg-limestone) {
  @apply pb-[55px] mb-0;
}