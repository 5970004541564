.carousel-monuments {
  .tns-nav {
    @apply hidden;
  }

  .tns-controls {
    @apply w-full absolute left-0 top-1/2 -translate-y-1/2 flex justify-between z-10;

    &:focus {
      button {
        @apply outline outline-1 outline-primary-3 outline-offset-4;
      }
    }

    button {
      @apply rounded-full bg-tertiary-4 text-primary-3 border border-primary-3 w-[50px] h-[48px] text-ms2 absolute left-0 sm:-left-ms0 z-10;
      @apply transition duration-200 ease-in-out;
      @apply flex items-center justify-center;

      &:hover, &:focus {
        @apply bg-primary-3 text-tertiary-4;
      }

      &:active {
        @apply bg-[#780000] outline-0;
      }

      span {
        @apply inline-block;
      }

      &:first-child {
        @apply translate-x-[-24px] translate-y-[-50%];
      }

      &:last-child {
        @apply translate-x-[24px] translate-y-[-50%] right-0 sm:-right-ms0 left-auto;
      }

      &:disabled {
        @apply invisible;
      }
    }
  }
}