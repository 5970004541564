@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "./fonts.css";
@import "./imports.css";

@layer base {
  @property --offset {
    syntax: '<length>';
    inherits: false;
    initial-value: 4px;
  }

  :root {
    --container-gutter: theme("ms.1");
    --container-width: theme("screens.xl");
    --header-height: 91px;

    @screen sm {
      --container-gutter: theme("ms.4");
    }

    @screen lg {
      --header-height: 166px;
    }

    @screen xl {
      --container-gutter: 100px;
    }
  }

  html {
    font-size: 18px;
    @apply font-primary font-normal text-tertiary-1;
  }

  body {
    @apply bg-tertiary-4;

    &.preload * {
      @apply !transition-none;
    }
  }

  body:not(:has(main .breadcrumbs)):not(.home) {
    main {
      margin-top: 0;
    }

    & > header > div {
      @apply bg-primary-1;
    }
  }

  body.home {
    main {
      margin-top: calc(-1 * var(--header-height));
    }
  }

  main {
    margin-top: calc(-1 * var(--header-height));
  }

  h1, .h1 {
    @apply text-[2.741rem] font-secondary font-medium mb-ms0;
    line-height: 1.2;

    &.small {
      @screen sm {
        @apply text-ms5;
      }
    }

    .date {
      @apply text-ms1 block;

      @screen sm {
        @apply text-ms4;
      }
    }

    @screen md {
      @apply text-ms6
    }

    @screen lg {
      @apply text-ms7;
    }

    @screen 2xl {
      &.display {
        @apply text-ms8;
        line-height: 1.175;
      }
    }
  }

  h2, .h2 {
    @apply text-ms3 font-secondary font-medium mb-[2.25rem] mt-ms4;
    line-height: 1.1;

    &.bordered {
      @apply border-t border-b border-current py-ms0;
    }

    &.small {
      @screen sm {
        @apply text-ms4;
      }
    }

    @screen md {
      @apply text-ms4;
    }

    @screen lg {
      @apply text-ms6;
    }
  }

  article.event, article.news {
    h2, .h2 {
      @apply mb-ms1;

      @screen lg {
        @apply text-ms4;
      }
    }
  }

  h3, .h3 {
    @apply text-ms1 font-tertiary font-normal mb-ms-2 mt-ms2;
    line-height: 1.3;

    @screen sm {
      @apply text-ms2;
    }
  }

  h4, .h4 {
    @apply text-ms0 font-tertiary font-normal mb-ms-2 mt-ms2;
    line-height: 1.215;

    @screen sm {
      @apply text-ms1;
    }
  }

  p {
    @apply my-ms-2;

    &.large {
      @apply text-ms2;
      line-height: 1.2;
    }
  }

  .eyebrow {
    @apply text-ms0 font-tertiary font-medium text-primary-1 block uppercase mb-ms-2 tracking-[0.04rem];

    &:before {
      @apply icon icon-eyebrow text-tertiary-2 mr-ms-2 text-ms-1;
    }
  }

  .eyebrow-no-icon {
    @apply text-ms0 font-tertiary font-medium text-primary-1 block uppercase;

    &:before {
      @apply hidden;
    }
  }

  .eyebrow-small {
    @apply text-ms-1 font-tertiary font-medium uppercase block uppercase;
  }

  blockquote {
    @apply font-tertiary font-light italic;

    p {
      @apply text-ms1;

      @screen md {
        @apply text-ms3
      }
    }

    p.quote-footer {
      @apply font-primary text-ms0 not-italic before:content-["\2014"] underline underline-offset-8 decoration-secondary-1;
    }

    footer {
      @apply text-right;

      cite {
        @apply block not-italic font-bold;
      }
    }
  }

  /* .content figure {
    figcaption {
      @apply text-ms-1 mt-ms-1 border-l-2 border-tertiary-2 pl-ms-2;
    }
  } */

  details {
    summary {
      &::-webkit-details-marker {
        display: none;
      }
    }
  }

  #map_canvas {
    overflow: hidden;
    position: relative;
    border-color: black;
    border-width: 1px;
    border-style: solid;
    padding: 1px;
    margin-bottom: 8px;
  }

  #timeMachine {
    margin-bottom:20px;
    margin-top:14px;
  }

  #timeMachine .timeLabels {
    display: flex;
    width: 320px;
    justify-content: space-between;
  }

  /* #slideHandle {
    position: absolute;
    height: 23px;
    width: 116px;
    cursor: pointer;
    top: 7px;
    background-image: url(/images/2013/battle/map/battleMapSlider-slider.png);
  }

  #slideWrapper {
    position: relative;
    width: 320px;
    height: 23px;

    display: block;
    background-image: url(/images/2013/battle/map/battleMapSlider-gutter.png);
    background-size: contain;
  } */

  #map_canvas label {
    font-family: Arial, sans-serif;
    font-weight: normal;
    color: rgb(184, 184, 184);
    font-size: 11px;
    text-transform: none;
    background: none;
    padding-right: 0;
    margin-right: 0;
  }
}

@layer components {

  .code-chunk {
    @apply [&:not(:has(*))]:!my-0;
  }

  .google-map {
    @apply aspect-[1.75];
  }

  form.form {
    @apply text-left mt-ms5;

    h2, h3 {
      @apply text-primary-1;
    }

    h2, h3, p {
      @apply w-full;
    }

    .cheeze {
      @apply hidden;
    }

    span {
      @apply block mb-ms-4;
    }

    label {
      @apply block mb-ms1 text-primary-1 font-tertiary text-ms1;

      input, textarea, select {
        @apply block bg-tertiary-4 w-full py-ms-3 px-ms-1 font-primary text-tertiary-1 text-ms0;
      }
    }

    fieldset {
      @apply mb-ms1;

      legend {
        @apply block text-primary-1 font-tertiary text-ms1;
      }
    }

    .c-checkbox, .c-radio {
      input {
        @apply mr-ms-2;
      }

      label {
        @apply font-primary text-tertiary-1 text-ms0 inline mb-0;
      }
    }

    .c-checkbox {
      @apply flex items-start;

      input {
        @apply mt-ms-4;
      }
    }

    .c-radio {
      input, span {
        @apply inline w-auto;
      }
    }
  }

  .smugmug-image-container {
    @apply [&_img]:aspect-square [&_img]:object-contain [&>div:first-child]:bg-tertiary-2/30;

    a {
      @apply relative block !bg-none;
      @apply after:icon after:icon-search after:text-tertiary-1;
      @apply after:bg-white after:rounded-full after:p-[0.1rem];
      @apply after:absolute after:bottom-ms-3 after:right-ms-3;
      @apply after:transition after:duration-200 after:ease-in-out;
      @apply hover:after:bg-primary-3 hover:after:text-white;
      @apply focus:after:bg-primary-3 focus:after:text-white;
    }
  }

  dialog.modal {
    @apply backdrop:bg-black/75;

    figure {
      /* img {
        @apply max-h-[80vh];
      } */

      figcaption {
        @apply mt-ms0 text-white text-[16px] text-center;
      }
    }
  }

  .filter-search {
    @apply border border-primary-3 rounded-full inline-block lg:flex items-center py-ms-3 ml-auto;

    label {
      @apply relative pl-ms1 pr-ms3 after:icon after:icon-search after:absolute after:right-ms-1 after:top-[50%] after:translate-y-[-50%];
    }

    input {
      @apply bg-transparent placeholder-tertiary-1;
    }
  }

  .filter-control-dropdown {
    @apply relative inline-block mb-ms0 md:mb-0;

    button {
      @apply flex justify-between items-center font-medium bg-primary-3 text-tertiary-4 rounded-full px-ms-2 py-ms-4 text-left after:icon after:icon-keyboard_arrow_down after:text-ms2;

      span {
        @apply w-[180px] overflow-hidden whitespace-nowrap;
      }
    }

    fieldset {
      @apply hidden absolute left-0 top-full mt-ms-4 w-full bg-white shadow;
      @apply [&_span]:block [&_span]:px-ms-2 [&_span]:py-ms-4 [&_input:checked+span]:bg-primary-3 [&_input:checked+span]:text-tertiary-4 z-20;

      &.open {
        @apply block;
      }

      input {
        @apply sr-only;
      }
    }
  }

  .content {
    /* figure {
      img {
        @apply border border-primary-4 p-ms-4 text-black;
      }
    } */

    figure.image {
      @apply relative;

      figcaption {
        &:before {
          @apply content-[""] w-full h-full absolute inset-0;
        }
      }
    }

    hr {
      @apply border-tertiary-2 my-ms2;

      &:first-child {
        @apply my-0;
      }

      &:first-child + h2, &:first-child + h3, &:first-child + h4 {
        @apply mt-ms6
      }
    }

    ol {
      @apply list-none p-0 ml-ms1 [&_li>ol]:ml-0 my-ms0;
      counter-reset: section;

      ol {
        @apply !mt-ms-2;
      }

      li {
        @apply mb-ms-2 pl-ms2 relative;

        &:last-child {
          @apply mb-0;
        }

        &:before {
          counter-increment: section;
          content: counter(section)".";
          @apply absolute left-0 text-primary-3;
        }
      }
    }

    ul {
      @apply list-none p-0 ml-ms1 [&_li>ul]:ml-0 my-ms0;

      ul {
        @apply !mt-ms-2;
        li {
          &:before {
            @apply text-tertiary-1;
          }
        }
      }

      li {
        @apply mb-ms-2 pl-ms2 relative;

        &:last-child {
          @apply mb-0;
        }

        &:before {
          content: "\2722";
          @apply absolute left-0 text-primary-3 [.text-tertiary-3_&]:text-primary-4;
        }

        & > ul > li {
          &:before {
            content: "\25A0";
          }

          & > ul > li {
            &:before {
              content: "\25A1";
            }
          }
        }
      }
    }

    p:has(img) {
      @apply my-ms1;

      &:has(+ p.caption) {
        @apply mb-ms-2;
      }

      & + p.caption {
        @apply mb-ms1;
      }
    }

    a:not(.button,.link-cta,.video-cta) {
      @apply text-primary-3 transition duration-200 ease-in-out;
      transition: background-size 350ms;
      background: linear-gradient(0deg, currentcolor, currentcolor) no-repeat right bottom / 100% 1px;


      &:hover, &:focus {
        background-size: 0 1px;
        background-position-x: left;
      }
    }

    &.alert-bar a {
      @apply decoration-current;
    }

    & a[target="_blank"] {
      @apply after:icon after:icon-external-link after:ml-ms-6;
    }

    & a[href$=".pdf"] {
      @apply after:icon after:icon-download after:ml-ms-6;
    }

    @supports not (background: paint(something)) {
      a:not(.button,.link-cta) {
        transition: text-underline-offset 0.2s;
        text-underline-offset: 4px;

        &:hover, &:focus {
          text-underline-offset: 1px;
        }
      }
    }

    & table {
      @apply w-full text-[16px];

      tbody {
        tr {
          @apply border-b border-tertiary-2;
        }
      }

      & th, & td {
        @apply px-ms-1 py-ms-4;
        @apply text-left;
      }

      & :where(thead) th, & :where(thead) td {
        @apply border-t;
      }

      & th, & :where(thead) td {
        @apply font-semibold leading-tight;
      }
    }
  }

  .link-cta {
    @apply text-primary-3 font-medium;

    &:after {
      @apply icon icon-long-arrow text-ms-1 ml-ms0 transition duration-200 ease-in-out translate-x-0;
    }

    &:hover, &:focus {
      @apply cursor-pointer;

      &:after {
        @apply inline-block translate-x-2;
      }
    }

    &.link-cta--yellow {
      @apply text-primary-4;
    }
  }

  aside {
    .video-cta {
      @apply max-w-[300px];
    }
  }

  .video-cta {
    @apply relative inline-block;

    &:before {
      @apply content-["Watch"] font-tertiary uppercase bg-primary-3 text-white rounded-sm;
      @apply p-ms0 pl-ms5;
      @apply absolute bottom-0 left-0 right-0 mx-ms-1 mb-ms-1;
      @apply transform duration-200 ease-in-out;
    }

    &:hover, &:focus {
      &:before {
        @apply pl-ms6;
      }
    }

    &:active {
      &:before {
        @apply bg-[#780000];
      }
    }

    &:after {
      @apply icon icon-play_arrow absolute left-[1em] bottom-[1.2em] text-ms2 text-white;
    }

    &.video-cta--no-text {
      &:before {
        @apply content-[""] right-auto left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2;
        @apply m-0 p-0 w-[40px] h-[40px];
        @apply rounded-none;
        @apply transition duration-200 ease-in-out;
      }

      &:hover, &:focus {
        &:before {
          @apply scale-[1.2];
        }
      }

      &:active {
        &:before {
          @apply bg-[#780000] outline outline-1 outline-primary-3 outline-offset-4;;
        }
      }

      &:after {
        @apply left-1/2 top-1/2 bottom-auto -translate-x-1/2 -translate-y-1/2 text-ms4;
      }
    }
  }

  .button {
    @apply relative inline-block py-ms-1 px-ms1 rounded-sm no-underline transition duration-200 ease-in-out;
    @apply bg-primary-3 text-tertiary-3 border border-primary-3 font-medium leading-tight;

    &:hover, &:focus, &:active {
      @apply bg-white text-primary-3 drop-shadow-lg;
    }

    &:active {
      @apply bg-primary-3 text-tertiary-3 outline outline-1 outline-primary-3 outline-offset-4;
    }

    &.button--yellow {
      @apply bg-primary-4 text-tertiary-1 border-primary-4;

      &:hover, &:focus, &:active {
        @apply bg-primary-1/80 text-white;
      }

      &:active {
        @apply bg-primary-4 text-tertiary-1 outline outline-1 outline-primary-4 outline-offset-4;
      }

      &.button--inverse {
        @apply bg-transparent text-primary-4;

        &:hover, &:focus {
          @apply bg-primary-4 text-tertiary-1;
        }
      }
    }
  }

  div[class*="page-header"] + div, div[class*="page-header"] + section {
    @apply relative [body:not(.home)_&]:pt-ms9 container bg-[#E7E7DE] bg-[url('/images/san_jacinto_2022/concrete-wall.png')] my-0 py-ms6;

    &.bottom-shell-smoke {
      @apply pb-[55px];
    }
  }

  div.panorama-page-header {
    padding-top: var(--header-height);
  }

  .member-table-scroll {
    @apply overflow-scroll max-h-[80vh] lg:overflow-visible lg:max-h-none;
  }

  .samadmin {
    table.member-table {
      thead {
        tr:not(:first-child) {
          th {
            @screen lg {
              @apply top-[96px];
            }
          }
        }
      }
    }

    &.unfold-nav {
      table.member-table {
        thead {
          tr:not(:first-child) {
            th {
              @screen lg {
                @apply top-[158px];
              }
            }
          }
        }
      }
    }
  }

  .unfold-nav {
    table.member-table {
      thead {
        tr:not(:first-child) {
          th {
            @screen lg {
              @apply top-[62px];
            }
          }
        }
      }
    }
  }

  table.member-table {
    @apply hidden md:table !max-w-none table-fixed w-full min-w-[1000px];

    thead {
      @apply font-tertiary font-medium;

      th {
        @apply font-medium border-t-0;
      }

      tr:first-child {
        th {
          div {
            @apply block -rotate-45 origin-left whitespace-nowrap mt-ms6;
          }
        }
      }

      tr:not(:first-child) {
        th {
          @apply sticky top-0 translate-y-0 bg-tertiary-4 relative;
          @apply before:content-[""] before:h-[3px] before:w-full before:bg-tertiary-2 before:absolute before:top-0 before:left-0;
          @apply after:content-[""] after:h-[3px] after:w-full after:bg-tertiary-2 after:absolute after:bottom-0 after:left-0;
        }
      }
    }

    td, th {
      @apply py-ms-1;
    }

    td:first-child, th:first-child {
      @apply w-[376px] pl-0;
    }

    tbody {
      @apply text-ms0;

      td:not(:first-child) {
        @apply text-tertiary-2 w-[60px] text-ms1;
      }
    }
  }
}

@layer utilities {
  .hide-mobile {
    @apply hidden md:grid;
  }

  .header-display {
    @screen lg {
      @apply text-ms8;
      line-height: 1.175;
    }
  }

  p.caption, span.caption {
    @apply text-ms-1 mt-ms-1 border-l-2 border-tertiary-2 pl-ms-2 italic;
  }

  p.caption-title, span.caption-title {
    @apply font-tertiary font-medium text-ms0 not-italic;
  }

  p.quote-footer {
    @apply font-tertiary font-medium text-right;
  }

  p.callout {
    @apply inline-block font-bold w-auto text-primary-1 bg-primary-4 py-ms-1 px-ms1 my-0;
  }

  img.bordered-image {
    @apply border border-primary-4 p-ms-4 text-black;
  }

  figure:has(.bordered-image) {
    img {
      @apply border border-primary-4 p-ms-4 text-black;
    }
  }

  figure.bordered-image {
    img {
      @apply border border-primary-4 p-ms-4 text-black;
    }
  }

  .bottom-shell-smoke {
    @apply pb-ms7 after:w-full after:h-[55px] after:absolute after:bottom-0 after:left-1/2 after:-translate-x-1/2 after:bg-[url('/images/san_jacinto_2022/smoke-divider.png')];
    @apply after:bg-[length:2560px] after:bg-center;

    & + .my-ms6 {
      @apply mt-ms2;
    }
  }

  .top-shell-smoke {
    @apply before:w-full before:h-[55px] before:absolute before:top-0 before:left-1/2 before:-translate-x-1/2 before:bg-[url('/images/san_jacinto_2022/smoke-divider.png')] before:scale-[-1] before:z-10;
    @apply before:bg-[length:2560px] before:bg-center;
  }

  body:not(:has(main .breadcrumbs)) {
    .global-breadcrumbs {
      @apply block;
    }
  }

  /* .text-default:not([class*="bg-"]) + .text-default:not([class*="bg-"]) {
    @apply pt-0;
  } */

  :where(.content) p.full-content-width {
    @apply !max-w-none my-ms6;
  }

  :where(.content) p.full-page-width {
    clear: both;
    margin-left: calc(var(--container-gutter)*-1) !important;
    margin-right: calc(var(--container-gutter)*-1) !important;
    max-width: none !important;
    @apply my-ms6;

    img {
      @apply w-full;
    }
  }

  :where(.content) figure.full-page-width {
    clear: both;
    max-width: none !important;
    margin-left: calc(var(--container-gutter)*-1) !important;
    margin-right: calc(var(--container-gutter)*-1) !important;
    @apply my-ms6;

    figcaption {
      margin-left: var(--container-gutter) !important;
      margin-right: var(--container-gutter) !important;
    }

    img {
      @apply w-full;
    }
  }

  .drop-cap, .large-drop-cap {
    &::first-letter {
      @apply font-tertiary text-[63px] text-primary-3 float-left leading-[0.83] pr-[0.1rem];
    }
  }

  .large-drop-cap {
    @apply md:text-ms1 md:font-tertiary md:font-medium md:leading-[1.4];

    &::first-letter {
      @apply md:text-[119px] md:leading-[0.79];
    }
  }

  .rock-background {
    @apply relative bg-tertiary-3 content-within:relative text-tertiary-1;
    @apply before:bg-[url('/images/san_jacinto_2022/rock_texture.jpg')] before:opacity-30;
    @apply before:absolute before:inset-0 object-cover;
  }
}

.tagify__dropdown {
  @apply !z-[65002];
}

.tagify {
  @apply max-h-[150px] overflow-scroll w-full;
}
