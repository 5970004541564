.platforma-site-header {
  top: calc(-1 * var(--header-height));

  height: var(--header-height);

}

.samadmin .platforma-site-header {
  top: calc(-1 * calc(var(--header-height) - 97px));
}

.platforma-site-header {
  &.unfold {
    .mega-menu {
      @screen lg {
        @apply pt-ms2 bottom-[6px];
      }
    }
  }

  .mega-menu {
    li.open {
      & > ul {
        @apply block;
      }
    }

    @screen lg {
      & > ul > li > ul {
        li:first-child a {
          @apply pt-0;
        }
      }
    }
  }

  .isGrandParent:not(.open) {
    & > div {
      @apply pt-0;
    }
  }

  .isGrandParent.open {
    & > div {
      @apply grid;
    }
  }

  nav[aria-label="Primary"] {
    & > ul > li > span {
      @apply underline transition-all duration-200 ease-in-out;
      @apply underline-offset-[5px] decoration-primary-4/[0];
    }
  }

  a:not(.button) {
    @apply underline transition-all duration-200 ease-in-out;
    @apply underline-offset-[5px] decoration-primary-4/[0];

    @screen lg {
      &:hover, &:focus, a.active {
        @apply text-primary-4 decoration-primary-4/[1];

        & + button {
          @apply text-primary-4;
        }
      }
    }
  }

  li.open {
    & > a, & > span {
      @apply text-primary-4 !decoration-primary-4/[1];

      & + button {
        @apply text-primary-4 rotate-180;
      }
    }
  }

  li.active {
    & > a, & > span {
      @apply !decoration-primary-4/[1];
    }
  }

  input[type="search"] {
    -webkit-appearance: none;
  }

  #site-search {
    @apply text-tertiary-3 border-[#D8D8D8] placeholder:text-tertiary-3 transition duration-200 ease-in-out;

    &:hover {
      @apply border-tertiary-3;
    }

    &:focus {
      @apply border-primary-4 outline-none;

      & + .icon-search {
        @apply text-primary-4;
      }
    }

    &:not(:placeholder-shown) {
      & + .icon-search {
        display: none;
      }
    }
  }

  &.menu-open {
    &:after, & ~ main:after, & ~ footer:after {
      content: "";
      background-color: rgba(0,0,0,0.8);
      @apply absolute inset-0;
    }

    .mobile-menu {
      @apply absolute top-0 right-0 w-[80%] h-[100vh] overflow-scroll flex pl-ms2;
    }
  }
}